module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.14.0_gatsby@5.14.0_babel-eslint@10.1.0_eslint@7.32.0__eslint-plugin-_4msjzsa3hpwvqfmfujf5f6tyti/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"And Then Some Development","short_name":"andthensome.nl","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.jpg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2de3262c513284a817a3ccb4031c03ee"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-offline@6.14.0_gatsby@5.14.0_babel-eslint@10.1.0_eslint@7.32.0__eslint-plugin-j_twrp5d7tumra4dqckqjyfkbqxq/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-background-image@1.6.0_gatsby@5.14.0_babel-eslint@10.1.0_eslint@7.32.0__eslint-plugin-_7vkibicbndtx4koj7kenne4afe/node_modules/gatsby-background-image/gatsby-browser.js'),
      options: {"plugins":[],"specialChars":"/:"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-web-font-loader@1.0.4/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Raleway:100,200,300,400,500,600,700,800,900","Pacifico:100,200,300,400,500,600,700,800,900","Open Sans:100,200,300,400,500,600,700,800,900"]}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-matomo@0.16.3_gatsby@5.14.0_babel-eslint@10.1.0_eslint@7.32.0__eslint-plugin-je_ciupn2z5ehfjfj3a6sbmzi2y44/node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"13","matomoUrl":"https://stats.andthensome.nl","siteUrl":"https://www.andthensome.nl"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-gtag@5.13.1_gatsby@5.14.0_babel-eslint@10.1.0_eslint@7.32.0__eslint-plug_vfo3tsxohethokaofximmsayau/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-83GQ6KG47J"],"gtagConfig":{}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.0_babel-eslint@10.1.0_eslint@7.32.0__eslint-plugin-jest@28.9.0_@typescript-eslint_zz2bgjuvbm5wrjl4wqzbdbasfe/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
